import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const FooterStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      footer {
        color: #ffffff;

        nav p,
        nav a {
          color: #ffffff !important;
        }

        .top-section,
        .bottom-section {
          border-bottom: none !important;
          background-color: #000000 !important;
        }

        .bottom {
          padding-top: 32px;

          @media (min-width: ${theme.breakpoints.lg}) {
            padding-top: 48px;
          }
        }

        section {
          background-color: ${theme.colors.dark.base.hex};
        }

        .minimal-footer & {
          .bottom-section {
            justify-content: flex-start;

            .nav {
              flex: 1;
              width: 50%;
              padding: 0 16px 0 0;

              @media (min-width: ${theme.breakpoints.md}) {
                flex: 0;
                min-width: 165px;
              }
            }

            .nav:first-of-type {
              @media (min-width: ${theme.breakpoints.md}) {
                padding: 0 32px 0 0;
              }

              @media (min-width: ${theme.breakpoints.lg}) {
                padding: 0 100px 0 0;
              }
            }
          }
        }
      }
    `}
  />
))

export default FooterStyles
