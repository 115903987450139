import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const HeaderStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-header {
        font-size: 18px;
        line-height: 24px;
        padding-top: 21px !important;
        padding-bottom: 33px !important;
        background: linear-gradient(
            90deg,
            ${theme.colors.tertiary.base.hex} 0%,
            ${theme.colors.primary.base.hex} 100%
          )
          left bottom #ffffff no-repeat !important;
        background-size: 100% 12px !important;

        img {
          margin-right: 28px;
        }

        /* Header Desktop CTA */
        .phone-link {
          font-weight: 700;
          color: #000000 !important;
          transition: background-color 0.3s;
          border-radius: 50px;
          background-color: ${theme.colors.primary.base.hex};
          padding: ${theme.spacing.xxs} ${theme.spacing.sm};

          &:hover {
            text-decoration: none;
            background-color: #ffb820;
          }

          &:active {
            background-color: #e8a231;
          }
        }

        img[alt='Logo'] {
          display: block;
          width: 200px;
          height: 100%;
        }

        .primary-link {
          text-transform: uppercase;

          &:hover {
            color: #000000 !important;

            .subnav {
              top: 70px !important;
            }
          }
        }
      }
    `}
  />
))

export default HeaderStyles
